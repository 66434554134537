import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import InformeBitacora from "./InformeBitcora";

const BitacorasInfo = ({ formData }) => {
  const {
    id,
    cedula,
    nombre,
    observacion,
    fecha,
    estado,
    Docente,
    Personal,
  } = formData;

  const formatDate = (dateString) => {
    if (!dateString) return "Fecha no especificada";
    const date = new Date(dateString);
    return format(date, "EEEE d 'de' MMMM 'del' yyyy HH:mm", { locale: es });
  };

  return (
    <div className="p-12 px-6 py-10 sm:px-10 sm:py-6 bg-white rounded-lg shadow-md lg:shadow-lg mt-2 mx-2 mb-2">
      <h2 className="text-lg text-[#0A6A50] font-bold">Registro de Bitácora:</h2>
      <div className="justify-between grid grid-cols-1 mt-2 text-sm">
        <p>
          <span className="font-semibold">Código:</span> {id}
        </p>
        <p>
          <span className="font-semibold">Cédula:</span> {cedula || "Cédula no especificada"}
        </p>
        <p>
          <span className="font-semibold">Nombre:</span> {nombre || "Nombre no especificado"}
        </p>
        <p>
          <span className="font-semibold">Fecha:</span> {formatDate(fecha)}
        </p>
        <p>
          <span className="font-semibold">Estado:</span> {estado || "Estado no especificado"}
        </p>
        <p>
          <span className="font-semibold">Observaciones:</span> {observacion || "No especificadas"}
        </p>
        {Docente && (
          <p>
            <span className="font-semibold">Asignado a Docente:</span> {Docente.nombre} ({Docente.cedula})
          </p>
        )}
        {Personal && (
          <p>
            <span className="font-semibold">Asignado a Personal:</span> {Personal.nombre} ({Personal.cedula})
          </p>
        )}
      </div>
    </div>
  );
};

const BitacoraRegistros = ({ bitacoras }) => {
  const [busqueda, setBusqueda] = useState("");
  const [resultadosFiltrados, setResultadosFiltrados] = useState([]);
  const [orden, setOrden] = useState("fecha-desc");

  useEffect(() => {
    // Lógica de filtrado optimizada
    const filteredData = bitacoras.filter((bitacora) => {
      const { id, cedula, nombre, observacion, estado } = bitacora;
      const filterText = busqueda.toLowerCase();
      return (
        (id && id.toString().toLowerCase().includes(filterText)) ||
        (cedula && cedula.toLowerCase().includes(filterText)) ||
        (nombre && nombre.toLowerCase().includes(filterText)) ||
        (observacion && observacion.toLowerCase().includes(filterText)) ||
        (estado && estado.toLowerCase().includes(filterText))
      );
    });
    setResultadosFiltrados(filteredData);
  }, [busqueda, bitacoras]);

  const ordenarBitacoras = (bitacoras) => {
    const bitacorasOrdenadas = [...bitacoras];
    bitacorasOrdenadas.sort((a, b) => {
      const fechaA = new Date(a.fecha);
      const fechaB = new Date(b.fecha);
      return orden === "fecha-asc" ? fechaA - fechaB : fechaB - fechaA;
    });
    return bitacorasOrdenadas;
  };

  const handleBusquedaChange = (event) => {
    setBusqueda(event.target.value);
  };

  const handleOrdenChange = (event) => {
    setOrden(event.target.value);
  };

  const bitacorasOrdenadas = ordenarBitacoras(resultadosFiltrados);

  return (
    <div className="flex flex-col items-center">
      <div className="w-1/2 p-12 px-6 py-10 sm:px-10 bg-white rounded-lg shadow-md lg:shadow-lg">
        <div className="flex flex-col">
          <label htmlFor="buscarBitacora" className="text-sm font-semibold text-gray-600">
            Buscar Bitácora:
          </label>
          <input
            type="text"
            id="busqueda"
            name="busqueda"
            placeholder="Buscar Bitácora"
            className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
            value={busqueda}
            onChange={handleBusquedaChange}
          />
        </div>

        <div className="flex flex-col mb-4 mt-4">
          <label htmlFor="ordenarPor" className="text-sm font-semibold text-gray-600">
            Ordenar por:
          </label>
          <select
            id="ordenarPor"
            className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
            value={orden}
            onChange={handleOrdenChange}
          >
            <option value="fecha-desc">Fecha más reciente</option>
            <option value="fecha-asc">Fecha más antiguo</option>
          </select>

          <InformeBitacora />
        </div>
      </div>

      <div className="grid grid-cols-3 mt-4">
        {bitacorasOrdenadas.map((bitacora, index) => (
          <BitacorasInfo key={index} formData={bitacora} />
        ))}
      </div>
    </div>
  );
};

export default BitacoraRegistros;
